import React, { useState } from "react";
import contactlogo from "../assets/Group.png";

const Popup = ({ message, onClose, isError }) => (
  <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center">
    <div
      className={`relative bg-gray-900 p-10 rounded-lg shadow-lg ${
        isError ? "border-2 border-red-500" : "border-2 border-blue-500"
      }`}
    >
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-red-600 hover:text-gray-500 transition-colors duration-200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <p className="text-white text-xl mb-4">{message}</p>
    </div>
  </div>
);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [popupState, setPopupState] = useState({
    show: false,
    message: "",
    isError: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://dso-genie-bk-dev2-0-804696281450.us-east1.run.app/contact-us`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.mobile, // Make sure to use "phone" here, as it's in the API request
            description: formData.message,
          }),
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully");
        setFormData({ name: "", email: "", mobile: "", message: "" });
        setPopupState({
          show: true,
          message: "Thanks for contacting us.",
          isError: false,
        });
      } else {
        console.error("Form submission failed");
        setPopupState({
          show: true,
          message: "Failed to submit the form. Please try again.",
          isError: true,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setPopupState({
        show: true,
        message: "An error occurred. Please try again later.",
        isError: true,
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white  p-8">
      <div className="max-w-4xl mx-auto mt-20">
        <div className="grid md:grid-cols-2 gap-20 items-center">
          {/* Left section */}
          <div className="mt-12">
            <div className="mb-8">
              <img
                src={contactlogo}
                alt="Contact illustration"
                className="w-25"
              />
            </div>
            <h1 className="text-4xl font-bold mb-4">Contact With Us</h1>
            <p className="text-lg mb-6">Have a query? We're here to provide answers!</p>
            <p className="text-white mb-8">
              DevSecOps Softwares Pvt.Ltd aims to deliver top-class solutions and
              services expertise by developing, deploying, and handling over
              effective solutions that propel the growth of clients' businesses.
            </p>
            <div>
              <h2 className="text-3xl font-semibold mb-2">Connect with us at:</h2>
              <a
                href="mailto:info@devsecopssoft.com"
                className="text-xl text-blue-400 hover:text-blue-300 transition-colors"
              >
                info@devsecopssoft.com
              </a>
            </div>
          </div>

          {/* Right section - Form */}
          <div className="rounded-lg mt-12 ml-12">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium mb-4">
                  Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 bg-gray-700 rounded-md border-2 border-blue-500 focus:outline-none"
                  placeholder="Enter your name"
                />
              </div>

              <div>
                <label htmlFor="mobile" className="block text-sm font-medium mb-1">
                  Mobile Number<span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 bg-gray-700 rounded-md border-2 border-blue-500 focus:outline-none"
                  placeholder="Enter your number"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-1">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 bg-gray-700 rounded-md border-2 border-blue-500 focus:outline-none"
                  placeholder="Enter your email"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium mb-1">
                  How can we help?<span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-3 py-2 bg-gray-700 rounded-md border-2 border-blue-500 focus:outline-none"
                  placeholder="Type your message here..."
                />
              </div>

              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md transition-colors"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Popup for form submission */}
      {popupState.show && (
        <Popup
          message={popupState.message}
          onClose={() => setPopupState({ ...popupState, show: false })}
          isError={popupState.isError}
        />
      )}
    </div>
  );
};

export default Contact;

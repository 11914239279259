import React from "react";
import vector from "../assets/Vector.png";
import vector1 from "../assets/Vector (1).png";
import vector2 from "../assets/Vector (2).png";
import vector3 from "../assets/Vector (3).png";
import vector4 from "../assets/Vector (4).png";
import servicebg from "../assets/servicebg.svg";
import Cloudmanagement from "../assets/Cloudmanagement (2).svg";

function CloudManagement() {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header Section */}
      <nav className="absolute top-0 left-0 w-full z-20">
        {/* Add your navbar content here */}
      </nav>
      {/* Background Image */}
      <img
        src={servicebg}
        alt="Background"
        className="w-full h-[50%] object-cover z-10"
      />

      {/* Overlay Content */}
      <div className="absolute inset-0 flex flex-col md:flex-row justify-center items-center z-20 text-white px-4 md:px-0">
        {/* Text content on the left */}
        <div className="flex flex-col items-start text-center md:text-left">
          <h1 className="text-white font-poppins text-4xl md:text-[60px] font-semibold leading-tight md:leading-[76px] tracking-wide md:tracking-[4.7px]">
            Cloud <br /> Management
          </h1>
        </div>

        {/* SVG Image on the right */}
        <img
          src={Cloudmanagement}
          alt="Policy Icon"
         className="hidden md:block w-50 h-50 mt-5 ml-44"
        />
      </div>

      {/* Section Template for Reuse */}
      {[
        {
          title: "Cloud Management Understanding",
          content:
            "Cloud management is the organized management of cloud computing products and services that operate in the cloud. It refers to the processes, strategies, policies, and technology used to help control and maintain public and private cloud, hybrid cloud, or multicloud environments.",
          image: vector,
          imageAlt: "Software Development Process",
        },
        {
          title: "Why Choose DevSecOps Software for Cloud Management Services?",
          content:
            "Hybrid cloud models are mainstream, as business continuity mandates public cloud adoption and CIOs demand a single pane of glass to manage the IT infrastructure. Disparate deployments like IaaS, PaaS, and SaaS in the cloud, including application support, backup administration, DevOps, make diverse resource skillsets, a criticality. Besides the benefits of cloud migration, key cloud management services we offer are support, proactive management, security, DevOps, capacity planning and cost control.",
          image: vector1,
          imageAlt: "Building Software",
        },
        {
          title: "Cloud Management Services",
          content:
            "Our enterprise infrastructure services division drives a comprehensive public cloud engineering and support offering for Amazon (AWS), Azure, and Google Cloud Platform’s cloud management services. We provide a one-stop hybrid-cloud remote managed service, and transformative cloud enablement services such as cloud application, migration, and analytics. We advise, implement, and manage public clouds.",
          image: vector2,
          imageAlt: "Planning Process",
        },
        {
          title: "Our Cloud Management Services Enlists",
          content:
            "Infrastructure Management (IaaS), Application Management (PaaS), Service Governance, Automation, DevOps & Cross Service support, Security and Compliance.",
          image: vector3,
          imageAlt: "Deploying Software",
        },
        {
          title: "Enterprise Benefits of Cloud Management",
          content:
            "Supporting Agile delivery in line with rapid organization changes and transformation projects, Regular patching and automation implementation,Drive IT delivery excellence in deployed cloud services through our CISF (Continuous Service Improvement Framework), Continuous Advisory with Key Finding Recommendations on a monthly and quarterly basis including cost control alerts and Service Governance through a single pane of glass, giving better visibility insights with analytic tools and dashboards",
          image: vector4,
          imageAlt: "Maintaining Software",
        },
      ].map((section, idx) => (
        <section key={idx} className="py-8 md:px-32 bg-gray-900">
          <div className="container mx-auto ">
            <div
              className={`flex ${
                idx % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
              } flex-col gap-20 items-center`}
            >
              <div className="relative p-2 rounded-lg inline-block">
                {/* First SVG Background */}
                <div className="absolute inset-0 z-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="285"
                    height="290"
                    viewBox="0 0 285 260"
                    fill="none"
                    className="w-full h-full"
                  >
                    <path
                      d="M180.49 258.347C133.696 258.346 89.9584 241.881 61.5073 228.068C22.3349 209.051 1.98982 185.13 1.03664 156.972C-1.53553 81.0445 132.007 10.3275 186.823 2.05617C191.467 1.35605 196.103 1 200.601 1C229.575 1 253.446 15.7041 266.093 41.3428C277.172 63.7993 283.112 93.2244 283.75 128.801C284.135 150.285 281.303 172.039 275.559 191.711C271.351 206.125 263.884 226.787 247.7 239.74C235.54 249.473 219.164 255.427 199.026 257.439C193.006 258.041 186.771 258.346 180.494 258.346C180.492 258.347 180.49 258.347 180.49 258.347Z"
                      stroke="url(#paint0_linear_224_2025)"
                      strokeWidth="1.59663"
                      strokeMiterlimit="10"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_224_2025"
                        x1="282.713"
                        y1="184.129"
                        x2="36.3844"
                        y2="63.9712"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#288FCE" />
                        <stop offset="1" stopColor="#263238" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                {/* Second SVG Background */}
                <div className="absolute inset-0 z-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="300"
                    height="243"
                    viewBox="0 0 268 243"
                    fill="none"
                    className="w-full h-full"
                  >
                    <path
                      d="M180.074 2.34538L180.014 1.95069L180.074 2.34538C209.973 -2.16633 236.809 9.14514 250.575 37.0511C262.853 61.9403 266.875 93.4466 267.368 120.952C267.726 140.969 265.125 162.132 259.511 181.362C254.858 197.301 247.677 214.618 234.461 225.196C222.069 235.115 205.809 239.539 190.192 241.099C145.23 245.592 97.4121 232.066 57.1678 212.528C44.0352 206.152 30.3491 197.93 19.8188 187.426C9.29247 176.925 1.93719 164.162 1.4131 148.689C0.826226 131.357 8.34843 114.046 20.8445 97.6802C33.3387 81.3171 50.781 65.9325 69.9684 52.4675C108.353 25.5309 153.636 6.33402 180.074 2.34538Z"
                      fill="url(#paint0_linear_224_2024)"
                      stroke="url(#paint1_linear_224_2024)"
                      strokeWidth="0.798314"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_224_2024"
                        x1="301.019"
                        y1="188.926"
                        x2="40.377"
                        y2="61.7964"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#34B9D2" />
                        <stop offset="1" stopColor="#4E87C7" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_224_2024"
                        x1="266.789"
                        y1="172.751"
                        x2="34.9004"
                        y2="58.9637"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#288FCE" />
                        <stop offset="1" stopColor="#263238" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                {/* Image to overlay */}
                <img
          src={section.image}
          alt={section.imageAlt}
           className="relative z-10 rounded-lg object-cover w-full h-auto"
        />
      </div>
      {/* Text Content */}
      <div className="w-full md:w-1/1">
        <h2 className="text-3xl md:text-3xl mx-4 font-semibold mb-4">{section.title}</h2>
        <p className="text-base md:text-lg mx-4 leading-relaxed">{section.content}</p>
      </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}
export default CloudManagement;

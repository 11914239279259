import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import homepage from "../assets/Homepagesvg.svg";
import mobilehomepage from "../assets/Home-mobile.svg";
import softwaredevsvg from "../assets/softaredevsvg.svg";
import securitysvg from "../assets/securitysvg.svg";
import devopssvg from "../assets/devopssvg.svg";
import cloudsvg from "../assets/cloudsvg.svg";
import dsogenielogo from "../assets/dsogenielogo.svg";

import AWS from "../assets/AWS-removebg-preview.png";
import GCP from "../assets/GCP-removebg-preview.png";
import Azure from "../assets/Azure-removebg-preview.png";
import Git from "../assets/gitlogo.png";
import Jenkins from "../assets/Jenkins-removebg-preview.png";
import Ansible from "../assets/Ansible-removebg-preview (1).png";
import Kubernetes from "../assets/Kubernetes-removebg-preview.png";
import Terraform from "../assets/Terraform-removebg-preview.png";
import Jira from "../assets/jira.png";
import Docker from "../assets/Docker.png";
import Helm from "../assets/helm.png";
import Argo from "../assets/argo.png";
import Oracle from "../assets/Oracle.png";
import Wiz from "../assets/wiz.png";
import Gitbucket from "../assets/gitbucket_logo-removebg-preview.png";

const Home = () => {
  // Services data
  const services = [
    {
      icon: <img src={softwaredevsvg} alt="Software Development Icon" />,
      title: "Software Development",
      description:
        "Software Development services are quite a part of the modern...",
      href: "/softwaredevelopment",
    },
    {
      icon: <img src={devopssvg} alt="DevOps Icon" />,
      title: "DevOps",
      description: "An undaunted combination of Development and operation...",
      href: "/devops",
    },
    {
      icon: <img src={securitysvg} alt="Software Security Icon" />,
      title: "Software Security",
      description:
        "Software Security refers to building security into design, the code,...",
      href: "/softwaresecurity",
    },
    {
      icon: <img src={cloudsvg} alt="Cloud Management Icon" />,
      title: "Cloud Management",
      description: "Cloud Management entails overseeing and optimizing...",
      href: "/cloudmanagement",
    },
  ];

  // Technology partners data
  const techPartners = [
    { name: "AWS", src: AWS },
    { name: "GCP", src: GCP },
    { name: "Azure", src: Azure },
    { name: "Git", src: Git },
    { name: "Jenkins", src: Jenkins },
    { name: "Ansible", src: Ansible },
    { name: "Kubernetes", src: Kubernetes },
    { name: "Terraform", src: Terraform },
    { name: "Jira", src: Jira },
    { name: "Docker", src: Docker },
    { name: "Helm", src: Helm },
    { name: "Argo", src: Argo },
    { name: "Oracle", src: Oracle },
    { name: "Wiz", src: Wiz },
    { name: "Gitbucket", src: Gitbucket },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  // Service card component
  const ServiceCard = ({ icon, title, description, href }) => (
    <a href={href} className="block">
      <div
        className={`
          p-6 rounded-lg transform transition-transform duration-300 
          hover:scale-105 hover:shadow-lg
          ${window.innerWidth < 640 ? "w-full" : "w-[300px] h-[310px]"}
        `}
        style={{
          borderRadius: "16px",
          border: "1px solid #288FCE",
          background:
            "linear-gradient(180deg, #17171D 0%, #0B3C6E 44.5%, #0061BF 100%)",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <div className="rounded-full flex items-center justify-center mb-16 mr-32">
          {typeof icon === "string" ? (
            <i className={`text-white text-xl lucide lucide-${icon}`}></i>
          ) : (
            icon
          )}
        </div>
        <h3 className="text-white text-xl font-semibold mb-2">{title}</h3>
        <p className="text-white text-sm">{description}</p>
      </div>
    </a>
  );

  return (
    <main className="bg-gray-900">
      {/* Welcome Section */}
      <div className="relative h-[88vh] flex items-start justify-start">
        {/* Background SVG */}
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${
              window.innerWidth >= 640 ? homepage : mobilehomepage
            })`,
          }} // Conditionally set the background image based on screen width
        ></div>
        {/* Overlay for color tint */}
        <div className="absolute inset-0 bg-black opacity-0"></div>{" "}
        {/* Added a black overlay for color tint */}
        {/* Content */}
        <div className="relative z-10 text-left ml-8 mt-40 sm:ml-32 sm:mt-60">
          {" "}
          {/* Adjusted margin for smaller screens */}
          <h2 className="text-white text-3xl sm:text-5xl font-bold">
            Welcome To
          </h2>
          <h2 className="text-white text-3xl sm:text-5xl font-bold mt-4">
            <span>'DevSecOps Softwares'</span>
          </h2>
          <h3
            className="text-white text-xl sm:text-3xl mt-4"
            data-content='"Innovation Today For Securing Tomorrow"'
          >
            "Innovation Today For Securing Tomorrow"
          </h3>
        </div>
      </div>

      {/* DSO Genie AI Section */}
      <div className="bg-gray-900 w-full mt-14 h-auto sm:h-70 relative">
        <div className="bg-gradient-to-r sm:ml-32 from-blue-500 to-gray-900 rounded-lg p-8 flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-x-8 w-[90%] sm:w-[85%] h-auto sm:h-50 relative border border-blue-500 mx-auto">
          <div className="text-white z-10">
            <h1 className="text-xl sm:text-2xl font-bold mb-4 hidden sm:block">
              Build with DSO Genie AI
            </h1>
            <h1 className="text-xl sm:text-2xl font-bold mb-4 block sm:hidden ">
            DSO Genie AI
            </h1>
            <p className="mb-4">
              "DSO Genie AI transformed our infrastructure, boosting our
              efficiency by 200%."
            </p>
            <a href="https://dsogenie.ai/" target="_blank">
              <button className="bg-black text-white py-2 px-4 rounded-lg shadow-md hover:bg-gray-700">
                Click to Visit
              </button>
            </a>
          </div>
        </div>
        <div className="absolute right-0 -top-5 sm:right-10 sm:-top-10 z-0">
          <img
            src={dsogenielogo}
            alt="Illustration of a genie with a modern, tech-inspired design"
            className={`
        ${window.innerWidth >= 640 ? "w-52 h-88 mr-20" : "w-28 h-28"}
      `}
          />
        </div>
      </div>

      {/* Our Services Section */}
      <div className="bg-gray-900 h-full mb-8 p-4 py-12">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-white text-3xl font-bold">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-12 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </div>

      {/* Brands */}
      <div className="bg-gradient-to-r from-blue-500 to-gray-900 mt-12 h-auto relative">
  <div className="flex flex-col md:flex-row items-center justify-between max-w-8xl p-8 mx-auto">
    {/* Text Section */}
    <div className="text-white mb-8 md:mb-0 md:mr-8 sm:text-left sm:ml-20">
      <p className="text-3xl">Trusted By</p>
      <p className="text-4xl font-bold">Great Brands</p>
    </div>

    {/* Logos Grid Section */}
    <div className="grid grid-cols-2 gap-20 sm:gap-6 md:grid-cols-4 md:ml-auto mr-20">
      {[
        { src: 'https://storage.googleapis.com/dsoassets/assets/syspro.png', alt: 'Logo of smarttrak' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/fuge_1_50.webp', alt: 'Logo of SVK Systems IT Experts' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/smart (2).png', alt: 'Logo of SVK Systems IT Experts' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/downloaooo.png', alt: 'Logo of SysPro IT Solutions' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/smart (2).png', alt: 'Logo of smarttrak' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/downloaooo.png', alt: 'Logo of smarttrak' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/syspro.png', alt: 'Logo of smarttrak' },
        { src: 'https://storage.googleapis.com/dsoassets/assets/fuge_1_50.webp', alt: 'Logo of smarttrak' },
      ]
        .slice(0, window.innerWidth < 640 ? 4 : 8) // Show 4 logos on mobile, 8 on larger screens
        .map((logo, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-md"
            style={{ width: '150px', height: '70px' }}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="object-contain w-full h-full"
            />
          </div>
        ))}
    </div>
  </div>
</div>


      {/* Strategic Technology Partners Section */}

      <div className="bg-gray-900 w-2xl mt-16 px-14">
  <h2 className="text-white text-3xl font-bold mb-8 ml-16 text-left">
    Strategic Technology Partners
  </h2>
  <Swiper
    modules={[Autoplay, Pagination, Navigation]}
    spaceBetween={10} // Increased space between slides for better visibility
    loop={true}
    autoplay={{
      delay: 1500,
      disableOnInteraction: false,
    }}
    className="mySwiper"
    breakpoints={{
      // when window width is >= 640px (laptop screens)
      640: {
        slidesPerView: 6,
      },
      // when window width is < 640px (mobile screens)
      0: {
        slidesPerView: 2, // Reduced to 2 slides for a better fit
      },
    }}
  >
    {techPartners.map((partner, index) => (
      <SwiperSlide key={index}>
        <div
          className={`flex flex-col items-center justify-center cursor-pointer transition-transform mb-12 duration-300 ${
            clickedIndex === index ? "scale-105" : ""
          }`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => setClickedIndex(index)}
        >
          <div className="w-24 h-24 bg-white rounded-full flex items-center justify-center p-2"> {/* Reduced size for mobile */}
            <img
              src={partner.src}
              alt={partner.name}
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
</div>


    </main>
  );
};

export default Home;

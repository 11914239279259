// App.js

import React from 'react';
import { BrowserRouter, Routes,Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from '../src/pages/Home';
import About from './pages/About.jsx';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Softwaredevelopment from './pages/Softwaredevelopment';
import Devops from './pages/Devops';
import CloudManagement from './pages/Multicloud';
import Softwaresecurity from './pages/Softwaresecurity';
import PrivacyPolicy from './pages/PrivacyPolicy';


const App = () => {
  return (
    
          <BrowserRouter>
           <Header />
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/About" element={<About/>}/>

            <Route path="/Careers" element={<Careers />}/>
            <Route path="/Contact" element={<Contact />}/>
      
            <Route path="/Softwaredevelopment" element={<Softwaredevelopment />}/>
            <Route path="/Devops" element={<Devops />}/>
            <Route path="/CloudManagement" element={<CloudManagement />}/>
            <Route path="/Softwaresecurity" element={<Softwaresecurity />}/>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
            {/* <Route path="/Scrollbar" element={<Scrollbar/>}/> */}
  
            {/* Add more routes for other pages as needed */}
          </Routes> 
          <Footer/>
          </BrowserRouter> 
  );
};

export default App;

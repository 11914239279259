// PrivacyPolicy.js
import softwaredev from "../assets/privacypolicysvg.svg";
import React from 'react';
import policy from "../assets/policy.svg";

const PrivacyPolicy = () => {
  return (
    <div className="bg-[#202028] min-h-screen flex flex-col">
   <div className="w-full h-[70vh] relative">
  {/* Navbar */}
  <nav className="absolute top-0 left-0 w-full z-20">
    {/* Add your navbar content here */}
  </nav>

  {/* Background Image */}
  <img
    src={softwaredev}
    alt="Background"
    className="w-full h-full object-cover z-10"
  />

  {/* Overlay Content */}
  <div className="absolute inset-0 flex flex-col md:flex-row justify-center items-center z-20 text-white px-4 md:px-0">
    {/* Text content on the left */}
    <div className="flex flex-col items-center md:items-start text-center md:text-left">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">PRIVACY POLICY</h1>
    </div>

  {/* SVG Image on the right */}
  <img
    src={policy}
    alt="Policy Icon"
    // className="w-50 h-48 mt-12 ml-72" 
     className="w-50 h-48 md:w-50 md:h-48 mt-6 md:mt-0 md:ml-12 lg:ml-20"
  />
</div>
</div>
    {/* Rest of the content */}
    <div className="max-w-5xl mx-auto p-5 font-sans text-white leading-relaxed px-8 py-8">
      <p className="font-bold  text-lg text-white">Effective Date: 08-02-2024</p>
      <h3 className="text-2xl mt-5 text-white">Your privacy is important to us</h3>
      <br/>
      <p>DevSecOps Softwares Pvt. Ltd is located at:</p>
      <address className="not-italic">
        1505, Tower-7, Shri Radha Sky Garden,<br /> 
        Sector-16B Greater Noida West-201009,<br /> 
        UP, India
      </address>
      <p>
        It is Devops's policy to respect your privacy regarding any information
        we may collect while operating our website. This Privacy Policy applies
        to <a className="text-blue-400 hover:underline" href="https://devsecopssoft.com/">devsecopssoft.com/</a> (hereinafter,
        "us", "we", or "devsecopssoft.com"). We respect your privacy and are
        committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        ("Privacy Policy") to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we
        may disclose the information to third parties. This Privacy Policy
        applies only to information we collect through the Website and does not
        apply to our collection of information from other sources.
      </p>
      <p>
        This Privacy Policy, together with the Terms of Service posted on our
        Website, set forth the general rules and policies governing your use of
        our Website. Depending on your activities when visiting our Website, you
        may be required to agree to additional terms of service.
      </p>
      <h2 id="table-of-contents" className="text-2xl mt-5 text-white">Contents</h2>
      <p>Click below to jump to any section of this privacy policy</p>
      <ol className="list-decimal pl-5">
        <li>
          <a className="text-blue-400 hover:underline" href="#PII"><strong>Personally-Identifying Information</strong></a>
        </li>
        <li>
          <a className="text-blue-400 hover:underline" href="#Security"><strong>Security</strong></a>
        </li>
        <li>
          <a className="text-blue-400 hover:underline" href="#ExternalLinks"><strong>Links To External Sites</strong></a>
        </li>
        <li>
          <a className="text-blue-400 hover:underline" href="#Ecommerce"><strong>E-commerce</strong></a>
        </li>
        <li>
          <a className="text-blue-400 hover:underline" href="#Changes"><strong>Privacy Policy Changes</strong></a>
        </li>
        <li>
          <a className="text-blue-400 hover:underline" href="#Credit"><strong>Contact Information & Credit</strong></a>
        </li>
      </ol>
      <h2 id="PII" className="text-2xl mt-5 text-white">1. Personally-Identifying Information</h2>
      <p><a className="text-blue-400 hover:underline" href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Security" className="text-2xl mt-5 text-white">2. Security</h2>
      <p>The security.</p>
      <p><a className="text-blue-400 hover:underline" href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="ExternalLinks" className="text-2xl mt-5 text-white">3. Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you visit a third-party site, you should review the privacy policy of every site you visit.
      </p>
      <p>We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services.</p>
      <p><a className="text-blue-400 hover:underline" href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Ecommerce" className="text-2xl mt-5 text-white">4. E-commerce</h2>
      <p>
        Those who engage in transactions with Devops – by purchasing Devops's
        services or products, are asked to provide additional information,
        including as necessary the personal and financial information required
        to process those transactions.
      </p>
      <p><a className="text-blue-400 hover:underline" href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Changes" className="text-2xl mt-5 text-white">5. Privacy Policy Changes</h2>
      <p>Although we may change this policy from time to time, we will notify you of any changes.</p>
      <p><a className="text-blue-400 hover:underline" href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Credit" className="text-2xl mt-5 text-white">6. Contact Information & Credit</h2>
      <p>
        This privacy policy was created at{' '}
        <a className="text-blue-400 hover:underline" href="https://privacyterms.io">privacyterms.io privacy policy generator</a>.
        If you have any questions about our Privacy Policy, please contact us
        via <a className="text-blue-400 hover:underline" href="mailto:info@devsecopssoft.com">email</a> or <a className="text-blue-400 hover:underline" href="tel:">phone</a>.
      </p>
      <p><a className="text-blue-400 hover:underline" href="#table-of-contents">Back to table of contents</a></p>
    </div>
    </div>
  );
};
export default PrivacyPolicy;


import React from "react";
import vector from "../assets/Vector.png";
import vector1 from "../assets/Vector (1).png";
import vector2 from "../assets/Vector (2).png";
import vector3 from "../assets/Vector (3).png";
import vector4 from "../assets/Vector (4).png";
import servicebg from "../assets/servicebg.svg";
import SoftwareDevelopment from "../assets/SoftwareDevelopment1.png";

function Softwaredevelopment() {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header Section */}

      <nav className="absolute top-0 left-0 w-full z-20">
        {/* Add your navbar content here */}
      </nav>

      {/* Background Image */}
      <img
        src={servicebg}
        alt="Background"
        className="w-full h-[50%] object-cover z-10"
      />

      {/* Overlay Content */}
      <div className="absolute inset-0 flex flex-col md:flex-row justify-center items-center z-20 text-white px-4 md:px-0">
        {/* Text content on the left */}
        <div className="flex flex-col items-start text-center md:text-left">
          <h1 className="text-white font-poppins text-4xl md:text-[60px] font-semibold leading-tight md:leading-[76px] tracking-wide md:tracking-[4.7px]">
            Software <br /> Management
          </h1>
        </div>

        {/* SVG Image on the right */}
        <img
          src={SoftwareDevelopment}
          alt="Policy Icon"
          className="hidden md:block w-50 h-50 mt-5 ml-44"
        />
      </div>

      {/* Section Template for Reuse */}
      {[
        {
          title: "Software Development Understanding",
          content:
            "At the beginning, we take our time to sit with our client and discuss their requirements. We then come up with a non-technical layout of the software development and its core features. This helps us clear any doubts and avoids errors in the development process. After understanding the client's requirements, company goals, vision, and target audience, we move to the second stage of development.",
          image: vector,
          imageAlt: "Software Development Process",
        },
        {
          title: "Building",
          content:
            "This is the building phase. After understanding all the necessary project requirements, we use various software development methodologies, such as Agile or Waterfall. During this phase, you will receive updates and suggestions from our project manager, keeping you informed during the software application's development. The software is thoroughly tested to eliminate any programming bugs.",
          image: vector1,
          imageAlt: "Building Software",
        },
        {
          title: "Planning",
          content:
            "Following the recommendations, we create a plan of action. In this phase, we work on all software and hardware requirements, project scope and deadlines, and any fixed milestones. We keep you informed about every step and maintain flexibility to avoid unforeseen difficulties during the software development phase.",
          image: vector2,
          imageAlt: "Planning Process",
        },
        {
          title: "Deploying",
          content:
            "At this stage, the software is not ready for end users. It needs to be hosted on markets and app stores before becoming available for use. During this phase, we take care of every aspect of your software that must be well-tested before reaching end users.",
          image: vector3,
          imageAlt: "Deploying Software",
        },
        {
          title: "Maintaining",
          content:
            "Now comes the maintaining phase. As software applications are not meant to last forever, upgrades will be necessary. We maintain engagement with you to ensure that the investment you made in software application development provides the best value for money, accommodating version upgrades, market evolution, and customer demands for new features.",
          image: vector4,
          imageAlt: "Maintaining Software",
        },
      ].map((section, idx) => (
        <section key={idx} className="py-8 md:px-32 bg-gray-900">
          <div className="container mx-auto ">
            <div
              className={`flex ${
                idx % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
              } flex-col gap-20 items-center`}
            >
              <div className="relative p-2 rounded-lg inline-block">
                {/* First SVG Background */}
                <div className="absolute inset-0 z-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="285"
                    height="290"
                    viewBox="0 0 285 260"
                    fill="none"
                    className="w-full h-full"
                  >
                    <path
                      d="M180.49 258.347C133.696 258.346 89.9584 241.881 61.5073 228.068C22.3349 209.051 1.98982 185.13 1.03664 156.972C-1.53553 81.0445 132.007 10.3275 186.823 2.05617C191.467 1.35605 196.103 1 200.601 1C229.575 1 253.446 15.7041 266.093 41.3428C277.172 63.7993 283.112 93.2244 283.75 128.801C284.135 150.285 281.303 172.039 275.559 191.711C271.351 206.125 263.884 226.787 247.7 239.74C235.54 249.473 219.164 255.427 199.026 257.439C193.006 258.041 186.771 258.346 180.494 258.346C180.492 258.347 180.49 258.347 180.49 258.347Z"
                      stroke="url(#paint0_linear_224_2025)"
                      strokeWidth="1.59663"
                      strokeMiterlimit="10"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_224_2025"
                        x1="282.713"
                        y1="184.129"
                        x2="36.3844"
                        y2="63.9712"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#288FCE" />
                        <stop offset="1" stopColor="#263238" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                {/* Second SVG Background */}
                <div className="absolute inset-0 z-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="300"
                    height="243"
                    viewBox="0 0 268 243"
                    fill="none"
                    className="w-full h-full"
                  >
                    <path
                      d="M180.074 2.34538L180.014 1.95069L180.074 2.34538C209.973 -2.16633 236.809 9.14514 250.575 37.0511C262.853 61.9403 266.875 93.4466 267.368 120.952C267.726 140.969 265.125 162.132 259.511 181.362C254.858 197.301 247.677 214.618 234.461 225.196C222.069 235.115 205.809 239.539 190.192 241.099C145.23 245.592 97.4121 232.066 57.1678 212.528C44.0352 206.152 30.3491 197.93 19.8188 187.426C9.29247 176.925 1.93719 164.162 1.4131 148.689C0.826226 131.357 8.34843 114.046 20.8445 97.6802C33.3387 81.3171 50.781 65.9325 69.9684 52.4675C108.353 25.5309 153.636 6.33402 180.074 2.34538Z"
                      fill="url(#paint0_linear_224_2024)"
                      stroke="url(#paint1_linear_224_2024)"
                      strokeWidth="0.798314"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_224_2024"
                        x1="301.019"
                        y1="188.926"
                        x2="40.377"
                        y2="61.7964"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#34B9D2" />
                        <stop offset="1" stopColor="#4E87C7" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_224_2024"
                        x1="266.789"
                        y1="172.751"
                        x2="34.9004"
                        y2="58.9637"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#288FCE" />
                        <stop offset="1" stopColor="#263238" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                {/* Image to overlay */}
                <img
          src={section.image}
          alt={section.imageAlt}
          className="relative z-10 rounded-lg object-cover w-full h-auto"
        />
      </div>

      {/* Text Content */}
      <div className="w-full md:w-1/1">
        <h2 className="text-3xl md:text-3xl mx-4 font-semibold mb-4">{section.title}</h2>
        <p className="text-base md:text-lg mx-4 leading-relaxed">{section.content}</p>
      </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default Softwaredevelopment;


import React from "react";
import { MapPin, Calendar } from "lucide-react";
import softwaredev from "../assets/softwaredevelopmentsvg.svg";

// Filter button component
const FilterButton = ({ label, isActive }) => (
  <button
    className={`px-3 py-1.5 sm:px-4 sm:py-2 text-sm sm:text-base rounded transition-colors border border-[#0061BF] rounded-[40px] mt-4 sm:mt-16 whitespace-nowrap ${
      isActive ? "text-white" : "rounded-md text-white hover:bg-gray-600"
    }`}
  >
    {label}
  </button>
);

// Job card component
const JobCard = ({ title, deadline, location, description, link }) => {
  // Check if the deadline has passed
  const isExpired =
    deadline !== "Coming Soon..." &&
    new Date(deadline) < new Date();

  return (
    <div
      className="p-4 sm:p-8 md:p-12 mb-6 sm:mb-12 bg-gradient-to-b from-slate-800 to-slate-900 border hover:border-[#0061BF] rounded-[20px] focus:outline-none mx-4 sm:mx-20"
      style={{
        boxShadow: "0px 0px 0px var(--sds-size-depth-0) #2A2A31",
      }}
    >
      <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-white mb-3">{title}</h3>
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 text-gray-300 mb-4 sm:mb-6">
        <div className="flex items-center gap-2">
          <Calendar size={16} />
          <span className="text-sm sm:text-base">Last date to apply: {deadline}</span>
        </div>
        <div className="flex items-center gap-2 mt-2 sm:mt-0">
          <MapPin size={16} />
          <span className="text-sm sm:text-base">Location: {location}</span>
        </div>
      </div>
      <div
        className="text-gray-300 mb-4 sm:mb-6 leading-relaxed text-sm sm:text-base"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {link && !isExpired ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-[#1E2E45] hover:bg-[#288FCE] text-white px-4 sm:px-6 py-2 rounded transition-colors rounded-[20px] border border-[#0061BF] text-sm sm:text-base"
        >
          Apply Now
        </a>
      ) : (
        <button
          disabled
          className="bg-gray-600 text-white px-4 sm:px-6 py-2 rounded transition-colors rounded-[20px] border border-gray-500 cursor-not-allowed text-sm sm:text-base"
        >
          {isExpired ? "Application Closed" : "Coming Soon"}
        </button>
      )}
    </div>
  );
};

const CareersPage = () => {
  const filterCategories = [
    { label: "View all", isActive: true },
    { label: "Internships", isActive: false },
    { label: "Marketing", isActive: false },
    { label: "Development", isActive: false },
    { label: "Design", isActive: false },
    { label: "Operations", isActive: false },
    { label: "Customer Services", isActive: false },
    { label: "Finance", isActive: false },
  ];

  const jobs = [
    {
      title: "Python Developer",
      deadline: "January 22, 2025",
      location: "Remote",
      description: `
        🚀 <strong>Exciting Opportunity for Python Developers at DevSecOps Softwares!</strong><br><br>
        Are you a passionate Python developer looking to kickstart your career or grow in the software industry? 🌟<br>
        Join our innovative team at DevSecOps Softwares, where you'll work on cutting-edge projects, including AI tools, educational platforms, and more.<br><br>
        <strong>Job Details:</strong><br>
        - <strong>Position:</strong> Python Developer<br>
        - <strong>Experience:</strong> 0–2 years<br>
        - <strong>Location:</strong> Remote<br><br>
        <strong>Key Skills:</strong><br>
        - Proficiency in Python<br>
        - Experience with frameworks like Flask and Django<br>
        - Knowledge of REST APIs and web development<br>
        - Familiarity with AI/ML concepts and tools<br>
        - Strong problem-solving and debugging skills<br><br>
        <strong>Bonus Skills:</strong><br>
        - Familiarity with cloud technologies like GCP<br><br>
        If you're a proactive learner with a passion for technology, we’d love to hear from you!
      `,
      link: "https://docs.google.com/forms/d/e/1FAIpQLSd0TKFEAJYWkOPDzj6HR3WLYMyFSc_Yc3t7DEud1g6LIupG7A/viewform?usp=sf_link",
    },
    {
      title: "Winter Internship Program",
      deadline: "Coming Soon...",
      location: "Remote",
      description:
        "Our winter internship program offers a unique opportunity for talented and ambitious individuals to dive into the world of technology, work on real-world projects, and learn from industry experts. Whether you're passionate about coding, fascinated by Clouds or eager to explore the world of DevOps, we have something for you. At DevSecOps Softwares, interns are not just observers; they are integral members of our team. Join us for a rewarding experience where you'll develop valuable skills, build meaningful connections, and set the stage for a successful career in the tech industry.",
      link: "",
    },
    {
      title: "Summer Internship Program",
      deadline: "May 20, 2024",
      location: "Remote",
      description:
        "Our summer internship program offers a unique opportunity for talented and ambitious individuals to dive into the world of technology, work on real-world projects, and learn from industry experts. Whether you're passionate about coding, fascinated by Clouds or eager to explore the world of DevOps, we have something for you. At DevSecOps Softwares, interns are not just observers; they are integral members of our team. Join us for a rewarding experience where you'll develop valuable skills, build meaningful connections, and set the stage for a successful career in the tech industry.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSfBU-J_WWsbq2GC1-VU9M5N_D1ockIYH_LhAI9Ok5zOc8ytAQ/closedform",
    },
    {
      title: "DevOps Expert",
      deadline: "February 15, 2024",
      location: "Remote",
      description:
        "A DevOps Engineer Expert plays a crucial role in bridging the gap between development and operations throughout the product lifecycle. Their responsibilities span from planning and development to testing, deployment, and ongoing support. By integrating project functions and resources, they streamline processes, ensure efficient collaboration, and improve overall productivity. They are also responsible for automating workflows, optimizing infrastructure, and maintaining system reliability. A DevOps expert focuses on continuous integration and delivery (CI/CD), ensuring smooth, scalable deployments while minimizing risks and downtime. Their work enhances both the speed and quality of product releases.",
      link: "#",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-6">
        {/* Careers Intro Section */}
        <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start mb-8 mt-16 sm:mt-32 sm:ml-44 gap-4">
          <div className="w-32 sm:w-auto">
            <img
              src={softwaredev}
              alt="Career illustration"
              className="h-[120px] w-[140px] sm:h-[152px] sm:w-[173px]"
            />
          </div>
          <div className="text-center sm:text-left mt-4 sm:mt-8">
            <h1 className="text-3xl sm:text-4xl font-bold">Careers</h1>
            <p className="text-base sm:text-lg text-gray-300 mt-2">
              Join our team of talented professionals who are passionate about
              technology, innovation, and excellence.
            </p>
          </div>
        </div>

        {/* Filter Categories */}
        <div className="flex gap-2 md:gap-4 mb-6 overflow-x-auto mx-4 sm:mx-20 pb-2 scrollbar-hide">
          {filterCategories.map((category, index) => (
            <FilterButton
              key={index}
              label={category.label}
              isActive={category.isActive}
            />
          ))}
        </div>

        {/* Job Cards */}
        <div className="space-y-6">
          {jobs.map((job, index) => (
            <JobCard
              key={index}
              title={job.title}
              deadline={job.deadline}
              location={job.location}
              description={job.description}
              link={job.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareersPage;

import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import softwaredev from "../assets/aboutsvg.svg";
import AboutMobileview from "../assets/Aboutmobileview (2).svg";
import Aboutus from "../assets/About us.svg";
import missionIcon from "../assets/mission.svg";
import innovationIcon from "../assets/innovation.svg";
import stabilityIcon from "../assets/stabillity.svg";
import integrityIcon from "../assets/ethis.svg";
import "font-awesome/css/font-awesome.min.css";
import "font-awesome/css/font-awesome.min.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const cards = [
    {
      title: "Our Mission",
      content:
        "At DevSecOps Innovations, our goal is to lead the way in secure software development, multi-cloud solutions, and DevOps methodologies. We are committed to delivering cutting-edge technology with an unwavering focus on security. Through innovation, collaboration, and client-centric solutions, we empower businesses to thrive in the digital landscape. Our goal is to be a trusted partner, setting industry benchmarks in excellence, and ensuring the highest standard of security across all facets of technology.",
      icon: missionIcon,
    },
    {
      title: "Innovation",
      content:
        "Transform your digital world with our advanced IT solutions. Harness the power of bespoke software, cloud optimization, and cybersecurity expertise tailored to your business. Elevate performance, and lead the charge in the continuously evolving technology. Our innovative IT solutions redefine possibilities, driving efficiency and success. Embrace the future with a partner committed to pushing boundaries and crafting unique solutions for your company's digital journey.",
      icon: innovationIcon,
    },
    {
      title: "Stability & Security",
      content:
        "Our focus on stability and security ensure Our sturdy system promises the triumvirate of velocity, steadfastness, and safety, ensuring a flawless user experience. With optimized processes and cutting-edge technology, we prioritize delivering swift performance while maintaining the utmost stability. Security is paramount, with stringent measures in place to safeguard content and user data, providing a trustworthy environment for all interactions.",
      icon: stabilityIcon,
    },
    {
      title: "Integrity and Ethics",
      content:
        "We beOur commitment to integrity and ethics forms the cornerstone of our operations. Upholding the highest standards, we prioritize transparency, honesty, and fairness in all endeavors. Ethical conduct guides every decision, fostering a culture of trust and accountability. We are dedicated to ensuring that our actions align with principles that promote integrity, creating a foundation for sustainable relationships and responsible business practices.",
      icon: integrityIcon,
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of cards visible at once
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 1500, // Slide every 3 seconds
    draggable: true, // Enable manual dragging with the cursor
    swipeToSlide: true, // Enable smooth sliding while dragging
    pauseOnHover: true, // Pause autoplay when user hovers over the slider
    arrows: false, // Disable arrow buttons
    responsive: [
      {
        breakpoint: 1024, // Adjust for tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // Adjust for mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-gray-900">
    <div className="w-full h-[80vh] relative">
  {/* Navbar */}
  <nav className="absolute top-0 left-0 w-full md:w-1/2 z-20">
    {/* Add your navbar content here */}
  </nav>

  {/* Background Image for Laptop and Mobile */}
  <img
    src={softwaredev}
    alt="Background"
    className="hidden md:block absolute top-0 left-0 h-full flex-shrink-0  fill-[rgba(247,238,235,0.2)] object-cover z-10"
  />
  <img
    src={AboutMobileview}
    alt="Background Mobile"
    className="block md:hidden absolute top-0 left-0 h-full w-full flex-shrink-0  object-cover z-10"
  />

  {/* Overlay Content */}
  <div className="absolute inset-0 flex flex-col md:flex-row justify-center items-center z-20 text-white">
    {/* Text content on the left */}
    <div className="flex flex-col items-start px-10 md:px-20">
      <h1 className="text-white font-poppins text-3xl md:text-[46.887px] font-semibold leading-tight md:leading-[75px] tracking-wide md:tracking-[4.689px] mb-4">
        About Us
      </h1>

      <h2 className="text-white font-poppins text-lg md:text-2xl font-normal leading-snug md:leading-[39px] mb-4">
        Welcome to DevSecOps Softwares Private Limited
      </h2>

      <p className="text-white font-poppins text-base md:text-xl font-light leading-snug md:leading-[28px]">
        Welcome to DevSecOps Softwares Private Limited, your partner in
        Technology, Innovation, and Excellence with a deep-rooted passion
        for Software Development, DevOps, Multi-Cloud Management, and
        Software Security. We empower Businesses to thrive in the Digital
        landscape.
      </p>
    </div>

    {/* SVG Image on the right */}
    <img
      src={Aboutus}
      alt="About Us Icon"
      className="hidden md:block w-50 h-60 mt-20 px-32"
    />
  </div>
</div>
      {/* Cards Section */}

      {/* Cards Section */}
      <div className="flex flex-wrap justify-center items-start gap-4 md:gap-6 px-4 py-8 md:py-12 max-w-7xl mx-auto">
      {cards.map((card, index) => (
        <div
          key={index}
          className="w-full md:w-[538px] h-[400px] md:h-[340px] flex-shrink-0 rounded-2xl border border-[#288FCE] bg-gradient-to-b from-[#1F2F46] to-[#20242F] shadow-lg p-4 md:p-6 relative group overflow-hidden"
        >
          <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-[2500ms] ease-in-out">
            {/* Icon */}
            <img
              src={card.icon}
              alt={`${card.title} Icon`}
              className="w-20 md:w-24 h-20 md:h-24 mb-4 md:mb-6 transition-all duration-[2500ms] ease-in-out 
                group-hover:scale-75 
                group-hover:absolute 
                group-hover:bottom-[-1rem] 
                group-hover:right-4
                sm:group-hover:bottom-[-1rem]
                sm:group-hover:right-4"
            />
            
            {/* Title */}
            <h3 className="text-2xl md:text-3xl font-semibold text-white text-center transition-all duration-[2500ms] ease-in-out 
              group-hover:absolute 
              group-hover:top-6 
              group-hover:left-4 
              group-hover:text-3xl
              md:group-hover:top-8 
              md:group-hover:left-6 
              md:group-hover:text-4xl">
              {card.title}
            </h3>
          </div>

          {/* Content */}
          <p className="text-white text-sm md:text-md opacity-0 transition-all duration-[2500ms] ease-in-out 
            group-hover:opacity-100 
            absolute 
            top-24 
            md:top-20 
            left-4 
            md:left-6 
            right-4 
            md:right-6 
            overflow-y-auto 
            max-h-[calc(100%-6rem)]
            md:max-h-[calc(100%-5rem)]
            leading-relaxed">
            {card.content}
          </p>
        </div>
      ))}
    </div>

      {/* Add team section */}
      {/* Cards Section - Team */}
      <section className="flex flex-col h-full justify-center py-12 items-center bg-gray-900 text-white">
      <div className="text-center md:text-left px-4 md:px-0 mb-6 md:mb-10 md:mr-32">
      <h2 className="text-3xl md:text-4xl font-bold">
        Meet Our <span className="text-blue-500">Team</span>
      </h2>
      <p className="text-white mt-2 text-sm md:text-base max-w-prose mx-auto md:mx-0">
        Get to know the talented individuals who make our company thrive.
        Our diverse team brings together a wealth of expertise.
      </p>
    </div>

        {/* Slider for Team Cards */}
        <Slider {...settings} className="w-3/4 ">
          {" "}
          {/* Added gap-x-5 */}
          {/* Card 3 */}
          <div className="group relative w-[313px] h-[367px]  m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/mr.Munnalalverma.jpg"
                className="h-full w-[313px] object-cover"
                alt="Card 3"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. Munnalal Verma
                  <br />
                  <span className="text-sm font-light">Managing Director</span>
                </h3>
              </div>
              <ul className="flex relative ">
                <li className="list-none mx-2">
                  <a
                    href="mailto:hr@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a className="text-white text-2xl">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a className="text-white text-2xl">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 1 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/AkashVerma.png"
                className="h-full w-[313px] object-cover"
                alt="Card 1"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. Akash Verma
                  <br />
                  <span className="text-sm font-light">Founder & CEO</span>
                </h3>
              </div>
              <ul className="flex relative">
                <li className="list-none mx-2">
                  <a
                    href="mailto:akash.verma@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://github.com/akash-devsecops"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://www.linkedin.com/in/akashv01/"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 2 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/MeghaVerma.jpg"
                className="h-full w-[313px] object-cover"
                alt="Card 2"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mrs. Megha Verma
                  <br />
                  <span className="text-sm font-light">Hr Manager</span>
                </h3>
              </div>
              <ul className="flex relative ">
                <li className="list-none mx-2">
                  <a
                    href="mailto:hr@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2 ">
                  <a className="text-white text-2xl">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://www.linkedin.com/in/megha-verma-782160104/"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 4 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/AryakumarArya.jpg"
                className="h-full w-[313px] object-cover"
                alt="Card 1"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. AryakumarArya
                  <br />
                  <span className="text-sm font-light">
                    DevOps/Cloud Engineer
                  </span>
                </h3>
              </div>
              <ul className="flex relative">
                <li className="list-none mx-2">
                  <a
                    href="mailto:arya.kumar@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2 ">
                  <a
                    href="https://github.com/arya-devsecops"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://www.linkedin.com/in/arya-kumar-arya-a8b198297/"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 5 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/BadalVerma.jpg"
                className="h-full w-[313px] object-cover"
                alt="Card 1"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. Badal Verma
                  <br />
                  <span className="text-sm font-light">Software Engineer</span>
                </h3>
              </div>
              <ul className="flex relative">
                <li className="list-none mx-2">
                  <a
                    href="mailto:badal.verma@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://github.com/badal-devsecops?tab=overview&from=2024-09-01&to=2024-09-30"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://www.linkedin.com/in/badal-verma-75760b253/"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 6 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/HarshSaini.jpg"
                className="h-full w-[313px] object-cover"
                alt="Card 1"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. Harsh Saini
                  <br />
                  <span className="text-sm font-light">Cloud Engineer</span>
                </h3>
              </div>
              <ul className="flex relative">
                <li className="list-none mx-2">
                  <a
                    href="mailto:harsh.kumar@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2 ">
                  <a
                    href="https://github.com/harsh-devsecops"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://www.linkedin.com/in/harsh-saini-62726b155/"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 7 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/Shivamshah.jpg"
                className="h-full w-[313px] object-cover"
                alt="Card 1"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. Shivam Sah
                  <br />
                  <span className="text-sm  font-light">Software Engineer</span>
                </h3>
              </div>
              <ul className="flex relative ">
                <li className="list-none mx-2">
                  <a
                    href="mailto:shivam.sah@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://github.com/shivam-devsecops"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://linkedin.com/in/shivam3112"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Card 8 */}
          <div className="group relative w-[313px] h-[367px] m-5 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
            <div className="absolute inset-0">
              <img
                src="https://storage.googleapis.com/bkt-dso-dev-01/team%20/priyanshuverma.png"
                className="h-full w-[313px] object-cover"
                alt="Card 1"
              />
            </div>
            <div className="absolute bottom-0 w-[313px] h-35 flex justify-center items-center z-10 flex-col backdrop-blur-lg shadow-lg border border-white/20">
              <div className="text-center">
                <h3 className="text-white tracking-wide font-medium text-lg">
                  Mr. Priyanshu Verma
                  <br />
                  <span className="text-sm font-light">Software Engineer</span>
                </h3>
              </div>
              <ul className="flex relative">
                <li className="list-none mx-2">
                  <a
                    href="mailto:priyanshu.verma@devsecopssoft.com"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://github.com/priyanshu-devsecops"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className="list-none mx-2">
                  <a
                    href="https://www.linkedin.com/in/priyanshu-verma94/"
                    target="_blank"
                    className="text-white text-2xl"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Slider>
      </section>

      {/* end team section */}
    </div>
  );
};

export default About;

import React from 'react';
import { motion } from 'framer-motion';


const Footer = () => {
    return (
        <footer className="bg-[#1A2638] text-white">
            <div className="container mx-auto px-4 md:px-6 lg:px-8 py-8 max-w-[1440px]">
                {/* Main Footer Content */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12"
                >
                    {/* Follow Us Section */}
                    <div className="text-center sm:text-left">
    <h2 className="text-lg font-bold mb-6">Follow Us</h2>
    <div className="flex justify-center sm:justify-start space-x-6">
        {["instagram", "facebook", "linkedin", "youtube"].map((platform, index) => (
            <motion.a
                key={index}
                href={
                    platform === "instagram"
                        ? "https://www.instagram.com/devsecops2023/"
                        : platform === "facebook"
                        ? "https://www.facebook.com/people/Devsecops-Softwares/pfbid02B1wUtPXHmWLxGFkPKJ9ZVcNfLPJmeAcVF6nbCKhcPMt7GQ1zaQXQ2idiQsiEyfQEl/"
                        : platform === "linkedin"
                        ? "https://www.linkedin.com/company/devsecops-softwares-pvt-ltd/"
                        : "https://www.youtube.com/channel/UC7f4wLdoUYM293kNCY66ghA"
                }
                whileHover={{
                    scale: 1.2,
                    color:
                        platform === "instagram"
                            ? "#C13584"  // Instagram's official color
                            : platform === "facebook"
                            ? "#1877F2"  // Facebook's official color
                            : platform === "linkedin"
                            ? "#0077B5"  // LinkedIn's official color
                            : platform === "youtube"
                            ? "#FF0000"  // YouTube's official color
                            : "#FFD700"
                }}
                transition={{ type: "spring", stiffness: 300 }}
                className="text-3xl"
            >
                <i className={`fab fa-${platform}`}></i>
            </motion.a>
        ))}
    </div>
</div>


                    {/* Services Section */}
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                        className="text-center sm:text-left"
                    >
                        <h2 className="text-lg font-bold mb-4">OUR SERVICES</h2>
                        <ul className="space-y-3">
                            {["Software Development", "DevOps", "Cloud Management", "Software Security"].map(
                                (service, index) => (
                                    <motion.li
                                        key={index}
                                        whileHover={{ scale: 1.1, color: "#A0AEC0" }}
                                        className="hover:text-gray-300 transition-colors duration-300 cursor-pointer"
                                    >
                                        <a href={`/${service.replace(/\s+/g, '').toLowerCase()}`}>{service}</a>
                                    </motion.li>
                                )
                            )}
                        </ul>
                    </motion.div>

                    {/* Contact Section */}
                    <motion.div
                        initial={{ opacity: 0, x: 50 }} // Changed from y to x for horizontal movement
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.3 }} // Adjusted timing for the animation
                        className="text-center sm:text-left lg:col-span-1"
                    >
                        <h2 className="text-lg font-bold mb-4">CONTACT</h2>
                        <div className="space-y-3 text-sm md:text-base">
                            <p className="leading-relaxed">
                                <strong>US Address : </strong><br className="md:hidden" />
                                8940 Kennebec Crossing Drive, Angier, NC, 27501.
                            </p>
                            <p className="leading-relaxed">
                                <strong>India Address : </strong><br className="md:hidden" />
                                 R21, South City Extension Shahjahanpur 242001, Uttar Pradesh, India.
                            </p>
                            <p className="hover:text-gray-300 transition-colors duration-300"><a href="mailto:info@devsecopssoft.com" target="_blank" rel="noopener noreferrer">info@devsecopssoft.com</a></p>
                            <p><a href="tel:+91 9761981880" target="_blank" rel="noopener noreferrer">+91 9761981880</a></p>
                            <p><a href="tel:+1(919)-353-4257" target="_blank" rel="noopener noreferrer">+1(919)-353-4257</a></p>
                        </div>
                    </motion.div>

                    {/* Useful Links Section */}
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.4 }}
                        className="text-center sm:text-left"
                    >
                        <h2 className="text-lg font-bold mb-4">USEFUL LINKS</h2>
                        <ul className="space-y-3">
                            {["About", "Privacy Policy", "Careers", "Contact-Us"].map((link, index) => (
                                <motion.li
                                    key={index}
                                    whileHover={{ scale: 1.1, color: "#A0AEC0" }}
                                    className="hover:text-gray-300 transition-colors duration-300 cursor-pointer"
                                >
                                    <a href={`/${link.replace(/\s+/g, '').toLowerCase()}`}>{link}</a>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                </motion.div>
            </div>

            {/* Copyright Section */}
            <div className="bg-[#1E2E45] text-center py-4 mt-8">
            <p className="text-sm md:text-base">
    &copy; {new Date().getFullYear()} Copyright:
    <motion.a
        href="/"
        whileHover={{ textDecoration: "underline", scale: 1.05 }}
        className="hover:underline ml-1"
    >
        DevSecOps Softwares Pvt. Ltd
    </motion.a>
</p>

            </div>
        </footer>
    );
};

export default Footer;
